<template>
  <div class="recommendation-container" id="recommendation-container">
    <div
      class="product-container"
      v-if="Object.keys(config).length && product.items.length"
      :style="cssProps"
    >
      <IconAndTitle
        :iconUrl="config.widget_settings.logo ? config.widget_settings.logo : ''"
        :brand="brand"
        :gender="gender"
        :category="category"
        :title="config.widget_settings.title"
        :subTitle="config.widget_settings.sub_title"
      />
      <div class="flex grid-gap-15 scroll-container">
        <!-- Left Arrow Div  -->
        <div
          class="rec_left"
          :class="
            !scrollView.left
              ? 'visibility_hidden'
              : config.widget_settings.scroll_arrows
                ? 'white_bg_arrow'
                : 'transparent_bg_left_arrow'
          "
          id="left-button"
          @click="swipeLeft"
        >
          <img alt="arrow_left" class="arrow left-arrow" src="../assets/images/arrow.svg" />  
        </div>
        <div class="flex grid-gap-15 rec_center" id="content" ref="content">
          <div
            class="product"
            :id="`product-list-item-${index}-${recomm_slug}-${configId}`"
            :class="config.product_card_layout.style"
            v-for="(n, index) in product.items.slice(0, config.widget_settings.max_products)" 
            :key="n"
          >
            <div v-if="isEventWrapper" :class="showVariantSelectScreen ? 'flex-grow' : 'flex-grow'">
              <ProductData
                :config="config"
                :functionalConfigAddToCart="functionalConfigAddToCart"
                :n="n"
                :index="index"
                :review_map="review_map"
                :isMounted="isMounted"
                :isEventWrapper="isEventWrapper"
                :follow="follow"
                :utm="utm"
                @showVariantSelectScreen="showVariantSelectScreenFunction"
                :recomm_slug="recomm_slug"
                :configId="configId" 
              />
            </div>
            <div v-else class="h-full" id="first-container">
              <fdk-product-card
                v-slot="productData"
                ref="productCard"
                class="h-full"
                id="second-container"
              >
                <fdk-accounts v-slot="accountsData" class="h-full">
                  <ProductData
                    :config="config"
                    :functionalConfigAddToCart="functionalConfigAddToCart"
                    :n="n"
                    :review_map="review_map"
                    :isMounted="isMounted"
                    :isEventWrapper="isEventWrapper"
                    :follow="follow"
                    :utm="utm"
                    :productData="productData"
                    :accountsData="accountsData"
                    :recomm_slug="recomm_slug"
                    :configId="configId"
                    :index="index"
                  />
                </fdk-accounts>
              </fdk-product-card>
            </div>
          </div>
        </div>
        <!-- Right Arrow Div  -->
        <div
          class="rec_right"
          :class="
            !scrollView.right
              ? 'visibility_hidden'
              : config.widget_settings.scroll_arrows
                ? 'white_bg_arrow'
                : 'transparent_bg_right_arrow'
          "
          id="right-button"
          @click="swipeRight"
        >
          <img alt="arrow_right" class="arrow right-arrow" src="../assets/images/arrow.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import IconAndTitle from '../../src/components/IconAndTitle.vue';
import ProductData from '../../src/components/ProductData.vue';
// import { dummyProductsDataWithVariant } from './DummyData.js';
// import ImageAndOffer from '../../src/components/ImageAndOffer.vue';
// import LabelInvertedHorizontal from '../../src/components/LabelInvertedHorizontal.vue';
// import LabelInvertedVertical from '../../src/components/LabelInvertedVertical.vue';
// import LabelHorizontal from '../../src/components/LabelHorizontal.vue';
// import PriceBox from '../../src/components/PriceBox.vue';
// import HighlightsBox from '../../src/components/HighlightsBox.vue';
// import { Utility } from "@gofynd/fdk-client-javascript/common";
// import AddToCartButton from './AddToCartButton.vue';

export default {
  props: {
    id: String,
    config: Object,
    functionalConfigAddToCart: Object,
    pageDetail: String,
    recomm_slug: String,
    isEventWrapper: Boolean,
    companyId: Number,
    applicationId: Number,
    configId: String
  },
  data() {
    return {
      isMounted: false,
      product: { items: [] },
      brand: '',
      gender: '',
      category: '',
      follow: {},
      review_map: {},
      utm: {
        source: 'recommendation',
        medium: '',
        name: 'utm'
      },
      scrollView: {
        left: false,
        right: true
      },
      showVariantSelectScreen: false,
      DEFAULT_IMG: `https://hdn-1.fynd.com/company/884/applications/000000000000000000000001/theme/pictures/free/original/theme-image-1598448644345.png`
    };
  },
  components: {
    IconAndTitle,
    ProductData
    // ImageAndOffer,
    // PriceBox,
    // HighlightsBox,
    // LabelInvertedHorizontal,
    // LabelInvertedVertical,
    // LabelHorizontal,
    // AddToCartButton,
  },
  name: 'productPreview',
  async mounted() {
    // let url = window.location.href;
    // this.pageDetail = Utility.convertUrlToAction(url);
    // this.recomm_slug = this.id;
    // await this.getData(this.recomm_slug, this.pageDetail);
    // console.log('Config => ', this.config);
    console.log('Recomm Slug => ', this.recomm_slug);
    this.isMounted = true;
    window?.FPI?.event.on('wishlist.add', this.wishAdd);
    window?.FPI?.event.on('wishlist.remove', this.wishRemove);
    this.utm.medium = this.recomm_slug;
    if (!this.isEventWrapper) {
      await this.getProduct(this.recomm_slug, this.pageDetail, this.configId);
    } else {
      // this.product = dummyProductsData;
      await this.getPreviewProducts();
    }
  },
  methods: {
    wishAdd(data) {
      this.follow[data.slug] = true;
    },
    wishRemove(data) {
      this.follow[data.slug] = false;
    },
    getPreviewProducts() {
      axios.interceptors.request.use((config) => {
        config.headers['x-company-id'] = window.parent?.location?.href?.split?.('/')?.[5];
        return config;
      });
      axios
        .get(
          `https://${window.location.host}/api/platform/v1.0/recommendations-new/product-preview?application_id=${this.applicationId}&company_id=${this.companyId}`
        )
        .then((res) => {
          this.product = res?.data;
          this.processProducts();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    processProducts() {
      try {
        console.log('Products 2 => ', this.product);
        this.brand = this.product?.items?.[0]?.brand?.name ?? 'brand';
        this.gender = this.product?.items?.[0]?.attributes?.gender ?? 'gender';
        this.category = this.product?.items?.[0]?.category?.[0]?.name ?? 'category';
        let f = {};
        let id_to_slug = {}; // needed bcoz FPI wishlist event uses slug where as  state.products.wishlistIds.subscribe use ids
        let keymap = this.config.reviews.keymap; //review keymap
        let r = {};
        const isEventWrapper = this.isEventWrapper;
        this.product.items.forEach(function (item) {
          //wishlist map
          f[item.slug] = false;
          id_to_slug[item.uid] = item.slug; //using id helps with FPI events

          //review data map
          let rmap = {
            ratings_total: isEventWrapper ? 100 : 0,
            ratings_count: isEventWrapper ? 25 : 0,
            reviews_count: isEventWrapper ? 30 : 0
          };
          item._custom_meta.forEach(function (meta_item) {
            if (keymap.ratings_total == meta_item.key) {
              rmap.ratings_total = parseInt(meta_item.value);
            } else if (keymap.ratings_count == meta_item.key) {
              rmap.ratings_count = parseInt(meta_item.value);
            } else if (keymap.reviews_count == meta_item.key) {
              rmap.reviews_count = parseInt(meta_item.value);
            }
          });
          rmap.rating =
            // parseInt(rmap.ratings_total / rmap.ratings_count) || 0;
            rmap.ratings_total / rmap.ratings_count || 0;
          r[item.slug] = rmap;
        });

        //Adds already wishlisted items to map
        window?.FPI?.state?.products?.wishlistIds?.subscribe((items) => {
          items.forEach((item) => {
            if (id_to_slug[item] !== undefined) {
              f[id_to_slug[item]] = true;
            }
          });
        });
        this.follow = f;
        this.review_map = r;
      } catch (error) {
        console.error("Failed in processing product's ::: ", error);
      }
    },
    showVariantSelectScreenFunction() {
      this.showVariantSelectScreen = !this.showVariantSelectScreen;
    },
    // Fisher-Yates shuffle algorithm
    shuffleArray(array) {
      try {
        const shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
      } catch (error) {
        console.error('Error in Shuffle => ', error);
      }
    },
    getProduct(recomm_slug, pageDetail, recommendation_id) {
      const url = window.location.origin;
      const apiUrl = `${url}/ext/recalpha/api/application/v1.0/recommend/${recomm_slug}`;
      let params = {};
      if (pageDetail.page.params && pageDetail.page.params.slug) {
        params.item_slug = pageDetail.page.params.slug[0];
      }
      if (pageDetail?.page?.type === 'cart') {
        const cartItem = window?.FPI?.state?.cart?._data?.all_cart_data?.items;
        params.item_slug = cartItem?.[0]?.product?.slug;
        params.page_slug = 'cart';
      }
      if (pageDetail?.page?.type === 'home') {
        params.page_slug = 'home';
      }
      if(recommendation_id){
        params.recommendation_id = recommendation_id
      }

      axios
        .get(apiUrl, {
          params: params
        })
        .then((response) => {
          this.product = response.data;
          if (this.product?.items?.length > 0) {
            this.product.items = this.shuffleArray(this.product.items);
          }
          this.processProducts();
        })
        .catch((error) => {
          console.error('Failed in fetching products => ', error);
        })
        .finally(() => {
          // this.product = dummyProductsDataWithVariant
          // this.processProducts()
        });
    },
    getColor(product, index) {
      const rating = this.review_map[product.slug].rating;
      if (rating && index <= rating) {
        return this.config.reviews.star_color;
      }
      return '#E6E6E6';
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
        // eslint-disable-next-line no-inner-declarations
        function scroll(timestamp) {
          const timeElapsed = timestamp - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          element.scrollLeft = scrollPos + scrollPixels * progress;
          if (timeElapsed < duration) {
            window.requestAnimationFrame(scroll);
          } else {
            return 0;
          }
        }

        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const parentDivWidth =
        document?.getElementsByClassName('card-layout-1')?.[0]?.clientWidth + 15;
      let scrollWidth = parentDivWidth > 300 ? -300 : -parentDivWidth;
      const content = this.$refs.content;
      this.scrollTo(content, scrollWidth, 800);
      this.scrollViewUpdate(scrollWidth);
    },
    swipeRight() {
      const parentDivWidth =
        document?.getElementsByClassName('card-layout-1')?.[0]?.clientWidth + 15;
      let scrollWidth = parentDivWidth > 300 ? 300 : parentDivWidth;
      const content = this.$refs.content;
      this.scrollTo(content, scrollWidth, 800);
      this.scrollViewUpdate(scrollWidth);
    },
    scrollViewUpdate(scrollPixels) {
      const content = this.$refs.content;
      this.scrollView.right =
        content.clientWidth + content.scrollLeft + scrollPixels - content.scrollWidth + 0.5 < 0;
      this.scrollView.left = content.scrollLeft + scrollPixels > 0;
    },
    openLink(url) {
      const userAgent = window.navigator.userAgent;

      const isMobileOrTablet =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      if (isMobileOrTablet) {
        // Open link in the same page for mobile or tablet devices
        window.location.href = url;
      } else {
        // Open link in a new page for desktop devices
        window.open(url, '_blank');
      }
    }
  },
  computed: {
    cssProps() {
      return {
        '--wishIconColor': this.config.wishlist.defaultColor,
        '--discountColor': this.config.discount.text_color,
        '--imageHeight': this.config.product_image.height + 'px',
        '--discountSize': this.config.discount.text_size + 'px',
        '--discountbgColor': this.config.discount?.background_color,
        '--namebrandColor': this.config.product_title.text_color,
        '--nameproductColor': this.config.product_title.secondary_text_color,
        '--namebrandSize': this.config.product_title.font_size + 'px',
        '--nameWidth': this.config.product_title.text_limit + 'ch',
        '--noOfLines': this.config.product_title.no_of_lines,
        '--brandFontWeight': this.config.product_title.highlight_brand_name ? 600 : 400,
        '--breakWhiteSpace': this.config.product_title.multiline_product_name ? 'nowrap' : 'normal',
        '--overflowVisible': this.config.product_title.multiline_product_name
          ? 'hidden'
          : 'visible',
        '--primaryPriceColor': this.config.price.text_color,
        '--priceFontWeight': this.config.price.highlight_price ? 600 : 400,
        '--secondaryPriceColor': this.config.price.secondary_text_color,
        '--pricefontSize': this.config.price.text_size + 'px',
        '--priceAlignment': this.config.price.alignment ? this.config.price.alignment : 'left',
        '--addtocartFont': this.config.cart_button?.font_size + 'px',
        '--addtocartlabelColor': this.config.cart_button?.label_color,
        '--addtocartbgColor': this.config.cart_button?.background_color,
        '--titleColor': this.config.widget_settings.title_color,
        '--subtitleColor': this.config.widget_settings.subtitle_color,
        '--cardBorderRadius': this.config.product_card_style.border_radius + 'px',
        '--cardBackgroundColor': this.config.product_card_style?.background_color,
        '--cardBorderColor': this.config.product_card_style.border_color,
        '--cardPadding': this.config.product_card_style.padding + 'px',
        '--cardMargin': this.config.product_card_style.margin + 'px',
        '--highlightfontSize': this.config.product_highlights.font_size + 'px',
        '--highlightMarginTop': this.config.product_highlights.margin_top + 'px',
        '--highlightBorderBottom': this.config.product_highlights.border_bottom ? '1px' : '0px',
        '--highlightColor': this.config.product_highlights.font_color,
        '--cardBoxShadow': this.config.product_card_style.box_shadow
          ? 'rgba(99, 99, 99, 0.5) 0px 2px 8px 0px'
          : 'none',
        '--leftArrowVisibility':
          this.config.widget_settings.max_products > 2 ? 'inherit' : 'hidden',
        '--rightArrowVisibility':
          this.config.widget_settings.max_products > 2 ? 'inherit' : 'hidden',
        '--titleAlignment': this.config.new_config.widget_settings.title_alignment
          ? this.config.new_config.widget_settings.title_alignment
          : 'left',
        '--subtitleAlignment': this.config.new_config.widget_settings.sub_title_alignment
          ? this.config.new_config.widget_settings.sub_title_alignment
          : 'left',
        '--productNameAlignment': this.config.product_title.alignment
          ? this.config.product_title.alignment
          : 'left',
        '--titleFontSize': this.config.new_config.widget_settings.title_font_settings.font_size
          ? `${this.config.new_config.widget_settings.title_font_settings.font_size}px`
          : `12px`,
        '--titleFontFamily': this.config.new_config.widget_settings.title_font_settings.font
          ? `${this.config.new_config.widget_settings.title_font_settings.font}`
          : 'inherit',
        '--subTitleBottomSpacing': this.config.new_config.widget_settings.sub_title_bottom_spacing
          ? `${this.config.new_config.widget_settings.sub_title_bottom_spacing}px`
          : 0,
        '--subtitleFontSize': this.config.new_config.widget_settings.sub_title_font_settings
          .font_size
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_size}px`
          : `12px`,
        '--titleFontWeight': this.config.new_config.widget_settings.title_font_settings.font_weight
          ? `${this.config.new_config.widget_settings.title_font_settings.font_weight}`
          : 600,
        '--subtitleFontWeight': this.config.new_config.widget_settings.sub_title_font_settings
          .font_weight
          ? `${this.config.new_config.widget_settings.sub_title_font_settings.font_weight}`
          : 500,
        '--reviewFontSize': this.config.new_config.reviews.review_font_size
          ? `${this.config.new_config.reviews.review_font_size}px`
          : `12px`,
        '--reviewFontColor': this.config.new_config.reviews.review_font_colour,
        '--ratingAverageFontSize':
          Math.max(10, Math.min(this.config.reviews.rating_detail.rating_average_font_size, 25)) +
          'px',
        '--ratingAverageFontColor': this.config.reviews.rating_detail.rating_average_font_color,
        // '--totalRatingCountFontSize':
        //   Math.max(
        //     10,
        //     Math.min(this.config.reviews.rating_detail.total_rating_count_font_size, 25)
        //   ) + 'px',
        '--totalRatingCountFontSize': this.config.reviews.rating_detail.total_rating_count_font_size
          ? `${this.config.reviews.rating_detail.total_rating_count_font_size}px`
          : `14px`,
        '--totalRatingCountFontColor':
          this.config.reviews.rating_detail.total_rating_count_font_color,
        '--contentBoxLeftPadding': `${this.config.content_box_style?.padding_left}px`,
        '--contentBoxTopPadding': `${this.config.content_box_style?.padding_top}px`,
        '--contentBoxRightPadding': `${this.config.content_box_style?.padding_right}px`,
        '--contentBoxBottomPadding': `${this.config.content_box_style?.padding_bottom}px`,
        '--contentBoxMargin': `${this.config.content_box_style?.margin}px`,

        // Add to cart button functional config
        '--addToCartBtnBorderRadius': `${this.functionalConfigAddToCart?.add_to_cart?.border_radius ? this.functionalConfigAddToCart?.add_to_cart?.border_radius : 4}px`,
        '--addToCartBtnHeight': `${this.functionalConfigAddToCart?.add_to_cart?.button_size ? this.functionalConfigAddToCart?.add_to_cart?.button_size : 40}px`,
        '--addToCartBtnFontSize': `${this.functionalConfigAddToCart?.add_to_cart?.font_size ? this.functionalConfigAddToCart?.add_to_cart?.font_size : 14}px`,
        '--addToCartBtnTextAlignment': `${this.functionalConfigAddToCart?.add_to_cart?.text_alignment ? this.functionalConfigAddToCart?.add_to_cart?.text_alignment : 'center'}`,
        '--addToCartBtnBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color : '#ffffff'}`,
        '--addToCartBtnHoverBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color_on_hover ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_background_color_on_hover : '#ffffff'}`,
        '--addToCartBtnBorderColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_border_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_border_color : 'black'}`,
        '--addToCartBtnLabelColor': `${this.functionalConfigAddToCart?.add_to_cart?.design?.button_label_color ? this.functionalConfigAddToCart?.add_to_cart?.design?.button_label_color : 'white'}`,

        // Select variant button functional config
        '--selectVariantBtnBorderRadius': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.border_radius ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.border_radius : 4}px`,
        '--selectVariantBtnHeight': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.button_size ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.button_size : 40}px`,
        '--selectVariantBtnFontSize': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.font_size ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.font_size : 14}px`,
        '--selectVariantBtnTextAlignment': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.text_alignment ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.text_alignment : 'center'}`,
        '--selectVariantBtnBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_background_color ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_background_color : '#ffffff'}`,
        '--selectVariantBtnHoverBgColor': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_background_color_on_hover ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_background_color_on_hover : '#ffffff'}`,
        '--selectVariantBtnBorderColor': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_border_color ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_border_color : 'black'}`,
        '--selectVariantBtnLabelColor': `${this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_label_color ? this.functionalConfigAddToCart?.add_to_cart?.variants_setting?.design?.button_label_color : 'white'}`,

        // Global Font Family
        '--globalFontFamily': this.isEventWrapper ? 'Inter' : 'inherit'
      };
    }
  }
};
</script>
<style lang="less" scoped>
@import '../less/appbindings.less';
</style>
